import dynamic from 'next/dynamic';

export const Header = dynamic(() => import('./Header'));
export const Footer = dynamic(() => import('./Footer'));
export const Button = dynamic(() => import('./Button'));
export const DownloadSection = dynamic(() => import('./DownloadSection'));
export const Tooltip = dynamic(() => import('./Tooltip'));
export const LazyImage = dynamic(() => import('./LazyImage'));
export const CommentItem = dynamic(() => import('./CommentItem'));
export const BreadCrumb = dynamic(() => import('./BreadCrumb'));
export const Filter = dynamic(() => import('./Filter'));
export const Dropdown = dynamic(() => import('./Dropdown'));
export const Pagination = dynamic(() => import('./Pagination'));
export const TopProgressBar = dynamic(() => import('./TopProgressBar'), {
  ssr: false,
});
export const ShareModelContext = dynamic(() => import('./ShareModelContext'));
