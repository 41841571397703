import { USER_BLOCK_KEY } from '@/constants';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { createContext, useContext, useMemo } from 'react';

const BlockContext = createContext<IBlockContext>({} as IBlockContext);

const BlockProvider = ({ children }: { children: React.ReactNode }) => {
  const [block, setBlock] = useLocalStorage(USER_BLOCK_KEY, {
    is: true,
  });
  const value = useMemo(() => {
    return {
      setBlock,
      block,
    } as unknown as IBlockContext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, setBlock]);

  return (
    <BlockContext.Provider value={value}>{children}</BlockContext.Provider>
  );
};

const useBlockContext = () => {
  const state = useContext(BlockContext);
  if (!state) {
    return Error('Not wrapped in MessengerContext.Provider');
  }
  return state;
};

export { BlockProvider, useBlockContext };
