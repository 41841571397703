import { concatPagination } from '@apollo/client/utilities';

export const DownloadLink = {
  ios: 'https://apps.apple.com/us/app/t%E1%BA%A1p-ch%C3%AD-trading/id1559875031',
  android:
    'https://play.google.com/store/apps/details?id=com.gigantecmedia.tapchitrading&hl=en',
};

export const SocialLink = {
  facebook: 'https://www.facebook.com/tapchitradingpage',
  youtube: 'https://www.youtube.com/c/T%E1%BA%A1pCh%C3%ADTrading',
  tiktok: 'https://www.tiktok.com/@tapchitrading',
};

export const APP_BROWSER_ID_KEY = 'tct-browser-id';

export const APP_QUERY_FIELD_POLICIES = {
  lesson_comment_web: concatPagination(['$lessonId']),
  course_rating: concatPagination(['$courseId']),
  magazine: concatPagination(),
};

export const MENU_ICON_ID = 'menu-icon';
export const MENU_ICON_ITEM_ID1 = 'menu-icon-item-1';
export const MENU_ICON_ITEM_ID2 = 'menu-icon-item-2';
export const MENU_ICON_ITEM_ID3 = 'menu-icon-item-3';

export const settingsSlider = {
  // dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

export const settingsSliderPlaylistMobile = {
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const settingsSliderInsight = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

export const settingsSliderInsightMobile = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1.5,
  slidesToScroll: 1.5,
};

export const USER_LOCAL_INFO_KEY = 'USER_LOCAL_INFO_TCT_KEY';
export const USER_BLOCK_KEY = 'USER_BLOCK_TCT_KEY';

export const IS_DEVELOP = process.env.NEXT_PUBLIC_DEVELOP === 'true';
